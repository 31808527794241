import { PricingPlanBenefitsServer } from '@wix/ambassador-pricing-plan-benefits-server/http';
import {
  Balance,
  GetBalanceRequest,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import { queryServices } from '@wix/ambassador-bookings-services-v2-service/http';
import {
  BulkRequest,
  BulkResponse,
  GetBusinessResponse,
  ServicesCatalogServer,
} from '@wix/ambassador-services-catalog-server/http';
import { queryServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/http';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { mapCatalogServiceResponseToService } from '@wix/bookings-uou-mappers';
import { ServiceType as ServiceV1Type } from '@wix/bookings-uou-types';
import type { V2Slot } from '@wix/ambassador-bookings-v2-booking/types';
import {
  calculateMultiSlotAvailability,
  queryAvailability,
} from '@wix/ambassador-bookings-availability-v1-slot-availability/http';
import {
  ControllerFlowAPI,
  Experiments,
  IHttpClient,
} from '@wix/yoshi-flow-editor';
import {
  Booking,
  CheckoutApiConfig,
  createConfig,
  getBooking,
  reschedule,
} from '@wix/bookings-checkout-api';
import { BULK_REQUEST_LIMIT } from '../constants/constants';
import { mapServiceToServiceV2 } from '../utils/service/mapServiceToV2';
import {
  RequestedFields,
  Service,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import {
  CalculateMultiSlotAvailabilityRequest,
  QueryAvailabilityRequest,
  QueryAvailabilityResponse,
  Slot,
} from '@wix/ambassador-bookings-availability-v1-slot-availability/types';
import { CalendarState } from '../components/BookingCalendar/controller';
import {
  fedopsQueryAvailabilityInteractionEnd,
  fedopsQueryAvailabilityInteractionStart,
} from '../utils/fedops/fedops';

interface CatalogDataFilter {
  servicesOptions?: {
    slug?: string;
    include: boolean;
    businessLocations?: string[];
    categories?: string[];
    id?: string[];
    isBookOnlineAllowed?: boolean;
    tags?: ServiceType[];
  };
  resourcesOptions?: {
    slug?: string;
    include: boolean;
  };
  locationsOptions?: {
    businessLocations?: string[];
    include: boolean;
  };
}

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const AVAILABILITY_SERVER_URL = '_api/availability-calendar';
export const CHECKOUT_SERVER_URL = '_api/checkout-server';
export const PRICING_PLAN__BENEFITS_SERVER_API = '_api/pricing-plan-benefits';
export const SERVICE_OPTIONS_AND_VARIANTS_SERVER_API =
  '_api/service-options-and-variants';

export type QueryServicesParams = {
  slug?: string;
  ids?: string[];
  isBookOnlineAllowed?: boolean;
  businessLocationIds?: string[];
  categories?: string[];
  types?: ServiceType[];
  offset?: number;
};
export class BookingsApi {
  private getAuthorization: Function;
  private experiments: Experiments;
  private bookingsCheckoutApiConfig: CheckoutApiConfig;
  private httpClient: IHttpClient;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private pricingPlanBenefitsServer: ReturnType<
    typeof PricingPlanBenefitsServer
  >;

  constructor({
    baseUrl,
    experiments,
    httpClient,
    getAuthorization,
  }: {
    baseUrl: string;
    experiments: Experiments;
    httpClient: IHttpClient;
    getAuthorization: Function;
  }) {
    this.getAuthorization = getAuthorization;
    this.experiments = experiments;
    this.bookingsCheckoutApiConfig = createConfig({
      getAuthorization: () => this.authorization,
      getBaseUrl: () => baseUrl,
      experiments: experiments as any,
      httpClient: httpClient as any,
    });
    this.httpClient = httpClient;

    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.pricingPlanBenefitsServer = PricingPlanBenefitsServer(
      `${baseUrl}${PRICING_PLAN__BENEFITS_SERVER_API}`,
    );
  }

  get authorization() {
    return this.getAuthorization();
  }

  async getBusinessInfo(): Promise<GetBusinessResponse> {
    return this.catalogServer
      .BusinessCatalog()({ Authorization: this.authorization })
      .get({ suppressNotFoundError: false });
  }

  async queryServices({
    isBookOnlineAllowed,
    slug,
    ids,
    businessLocationIds,
    categories,
    types,
    offset,
  }: QueryServicesParams) {
    const filter = {
      ...(slug ? { 'supportedSlugs.name': slug } : {}),
      ...(ids ? { id: { $in: ids } } : {}),
      ...(businessLocationIds
        ? { 'locations.business.id': { $hasSome: businessLocationIds } }
        : {}),
      ...(categories
        ? {
            'category.id': {
              $in: categories,
            },
          }
        : {}),
      ...(types
        ? {
            type: {
              $in: types,
            },
          }
        : {}),
      ...(isBookOnlineAllowed !== undefined
        ? { 'onlineBooking.enabled': isBookOnlineAllowed }
        : {}),
      $or: [{ hidden: false }, { hidden: { $exists: false } }],
    };

    const { data } = await this.httpClient.request(
      queryServices({
        conditionalFields: [RequestedFields.STAFF_MEMBER_DETAILS],
        query: {
          filter,
          paging: offset
            ? {
                limit: 100,
                offset,
              }
            : undefined,
        },
      }),
    );

    return data;
  }

  async getCatalogData({
    servicesOptions,
    resourcesOptions,
    locationsOptions,
  }: CatalogDataFilter): Promise<{ services: Service[] }> {
    const servicesCatalogService = this.catalogServer.Bulk();

    const bulkRequest: BulkRequest = this.createBulkRequest({
      servicesOptions,
      resourcesOptions,
      locationsOptions,
    });

    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: this.authorization,
    }).get(bulkRequest);

    const services = servicesOptions?.include
      ? catalogData.responseServices!.services!.map((service) =>
          mapServiceToServiceV2(
            mapCatalogServiceResponseToService(service),
            service,
          ),
        )
      : [];

    return {
      services,
    };
  }

  async getServiceVariants(
    serviceIds: string[],
  ): Promise<ServiceOptionsAndVariants[] | undefined> {
    try {
      const {
        data: { serviceOptionsAndVariantsList },
      } = await this.httpClient.request(
        queryServiceOptionsAndVariants({
          query: {
            filter: {
              serviceId: {
                $in: serviceIds,
              },
            },
          },
        }),
      );
      return serviceOptionsAndVariantsList;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  async getSlotsAvailability(
    queryAvailabilityRequest:
      | QueryAvailabilityRequest
      | CalculateMultiSlotAvailabilityRequest,
    flowAPI: ControllerFlowAPI,
    state: CalendarState,
  ): Promise<QueryAvailabilityResponse> {
    if (isMultiSlotsAvailabilityRequestType(queryAvailabilityRequest)) {
      const multiSlotsAvailabilityResponse = await this.httpClient.request(
        calculateMultiSlotAvailability(queryAvailabilityRequest),
      );
      return {
        availabilityEntries: multiSlotsAvailabilityResponse.data.slots,
        pagingMetadata: {
          ...multiSlotsAvailabilityResponse.data.cursorPagingMetadata,
        },
      };
    }
    try {
      fedopsQueryAvailabilityInteractionStart({
        flowAPI,
        state,
        request: queryAvailabilityRequest,
      });
      const response = (
        await this.httpClient.request(
          queryAvailability(queryAvailabilityRequest),
        )
      ).data;

      fedopsQueryAvailabilityInteractionEnd({
        flowAPI,
        response,
      });

      return response;
    } catch (e) {
      fedopsQueryAvailabilityInteractionEnd({
        flowAPI,
        error: e as string,
      });
      throw e;
    }
  }

  async getBookingDetails(bookingId: string) {
    return getBooking({ bookingId }, this.bookingsCheckoutApiConfig);
  }

  async rescheduleBooking({ booking, slot }: { booking: Booking; slot: Slot }) {
    const slotV2 = slot as V2Slot;
    return reschedule(
      { booking, slot: slotV2 },
      this.bookingsCheckoutApiConfig,
    );
  }

  private createBulkRequest({
    servicesOptions,
    resourcesOptions,
  }: CatalogDataFilter): BulkRequest {
    const nonEmptyCategoryFilter = {
      'category.id': {
        $contains: '-',
      },
    };

    return {
      requestServices: servicesOptions?.include
        ? {
            includeDeleted: false,
            query: {
              fieldsets: [],
              filter: JSON.stringify({
                'slugs.name': servicesOptions.slug
                  ? servicesOptions.slug
                  : undefined,
                ...(servicesOptions.id
                  ? { 'service.id': { $in: servicesOptions.id } }
                  : {}),
                'service.customProperties.uouHidden': 'false',
                ...(servicesOptions?.businessLocations
                  ? {
                      'service.schedules.availability.locations.businessLocation.ids':
                        servicesOptions?.businessLocations,
                    }
                  : {}),
                ...(servicesOptions.categories
                  ? {
                      $and: [
                        {
                          'category.id': {
                            $in: servicesOptions.categories,
                          },
                        },
                        nonEmptyCategoryFilter,
                      ],
                    }
                  : nonEmptyCategoryFilter),
                'schedules.tags': servicesOptions.tags
                  ? { $in: servicesOptions.tags.map(mapServiceTypeV2ToV1) }
                  : undefined,
                'service.policy.isBookOnlineAllowed':
                  servicesOptions.isBookOnlineAllowed,
              }),
              paging: {
                limit: 500,
              },
              fields: [],
              sort: [],
            },
          }
        : undefined,
      requestBusiness: {
        suppressNotFoundError: false,
      },
      requestListResources: resourcesOptions?.include
        ? {
            query: {
              fieldsets: [],
              filter: resourcesOptions.slug
                ? `{"slugs.name": "${resourcesOptions.slug}"}`
                : null,
              paging: {
                limit: BULK_REQUEST_LIMIT,
              },
              fields: [],
              sort: [],
            },
          }
        : undefined,
    };
  }

  async getPurchasedPricingPlans({
    contactId,
    authorization,
  }: {
    contactId: string;
    authorization: string;
  }): Promise<Balance[]> {
    const balanceRequest: GetBalanceRequest = {
      contactId,
    };
    const benefits = await this.pricingPlanBenefitsServer
      .MemberBenefits()({ Authorization: authorization })
      .getBalance(balanceRequest);
    return benefits.balanceItems || [];
  }
}

export const isMultiSlotsAvailabilityRequestType = (
  availabilityRequest:
    | QueryAvailabilityRequest
    | CalculateMultiSlotAvailabilityRequest,
): availabilityRequest is CalculateMultiSlotAvailabilityRequest => {
  return (
    (availabilityRequest as CalculateMultiSlotAvailabilityRequest).slots !==
    undefined
  );
};

const mapServiceTypeV2ToV1 = (serviceType: ServiceType): ServiceV1Type => {
  switch (serviceType) {
    case ServiceType.CLASS:
      return ServiceV1Type.GROUP;
    case ServiceType.COURSE:
      return ServiceV1Type.COURSE;
    case ServiceType.APPOINTMENT:
    default:
      return ServiceV1Type.INDIVIDUAL;
  }
};
